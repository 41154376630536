var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex-col w-64 h-screen absolute md:relative overflow-auto",
    class: {
      'hide-menu': _vm.hideMenu,
      'vertical-navigation-mode-over': _vm.resize()
    },
    attrs: {
      "id": "nav"
    }
  }, [_vm.user && _vm.user.type ? _c('div', {
    staticClass: "flex bg-white items-center justify-center sticky top-0 justify-between p-2"
  }, [_c('router-link', {
    staticClass: "flex items-center justify-center no-active px-4 py-3 h-12 w-full mx-auto",
    attrs: {
      "to": "/"
    }
  }, [_c('img', {
    staticClass: "align-middle border-none h-12",
    attrs: {
      "alt": "...",
      "src": _vm.image
    }
  })]), _vm.resize() ? _c('button', {
    staticClass: "focus:outline-none flex items-center inline",
    on: {
      "click": _vm.actionSidebar
    }
  }, [_c('i', {
    staticClass: "material-icons text-blue-600",
    staticStyle: {
      "font-size": "30px"
    }
  }, [_vm._v("close")])]) : _vm._e()], 1) : _vm._e(), _c('div', {
    staticClass: "h-full p-2 pt-4 bg-green-600 text-white",
    class: _vm.$colors.sideBar
  }, [_vm.getPermission('dashboard') ? _c('menu-link', {
    staticClass: "link",
    attrs: {
      "to": "/",
      "text": _vm.$t('dashboard'),
      "icon": "dashboard"
    }
  }) : _vm._e(), _vm.getPermission('warehouses') && _vm.getAccess('warehouses', 'create') ? _c('menu-link', {
    staticClass: "link",
    attrs: {
      "to": "/warehouses",
      "text": _vm.$t('warehouses'),
      "icon": "business"
    }
  }) : _vm._e(), ['warehouses', 'newWarehouse'].indexOf(_vm.$route.name) > -1 && _vm.getAccess('warehouses', 'create') ? _c('div', [_c('menu-link', {
    attrs: {
      "to": "/warehouses/new",
      "text": _vm.$t('new_warehouse'),
      "icon": "add",
      "submenu": "true"
    }
  })], 1) : _vm._e(), _vm.getPermission('products') && (_vm.getAccess('products', 'create') || _vm.user.type == 'StockManager') ? _c('menu-link', {
    staticClass: "link",
    attrs: {
      "to": "/products",
      "text": _vm.$t('products'),
      "icon": "local_mall"
    }
  }) : _vm._e(), ['products', 'newProduct'].indexOf(_vm.$route.name) > -1 && _vm.getAccess('products', 'create') ? _c('div', [_c('menu-link', {
    attrs: {
      "to": "/products/new",
      "text": _vm.$t('new_product'),
      "icon": "add",
      "submenu": "true"
    }
  })], 1) : _vm._e(), _vm.getPermission('analytics') && _vm.user.type != 'Seller' ? _c('menu-link', {
    staticClass: "link",
    attrs: {
      "to": "/callcenter",
      "text": _vm.$t('analytics'),
      "icon": "trending_up"
    }
  }) : _vm._e(), _vm.getPermission('dashboard') ? _c('menu-link', {
    staticClass: "link",
    attrs: {
      "to": "/expenses",
      "text": _vm.$t('expenses'),
      "icon": "monetization_on"
    }
  }) : _vm._e(), _vm.getPermission('spends') && _vm.getAccess('spends', 'read') ? _c('menu-link', {
    staticClass: "link",
    attrs: {
      "to": "/spends",
      "text": _vm.$t('spends'),
      "icon": "monetization_on"
    }
  }) : _vm._e(), _vm.getPermission('orders') ? _c('menu-link', {
    staticClass: "link",
    attrs: {
      "to": "/orders",
      "text": _vm.$t('orders'),
      "icon": "local_grocery_store"
    }
  }) : _vm._e(), ['orders', 'newOrder'].indexOf(_vm.$route.name) > -1 ? _c('div', [_vm.getAccess('orders', 'create') && ['NRP', 'CancelledOrder', 'ToRemind', 'TeleConsultant'].indexOf(_vm.user.type) == -1 ? _c('menu-link', {
    attrs: {
      "to": "/orders/new",
      "text": _vm.$t('new_order'),
      "icon": "add",
      "submenu": "true"
    }
  }) : _vm._e()], 1) : _vm._e(), _vm.user.type == 'TeleConsultant' || _vm.user.type == 'NRP' || _vm.user.type == 'CancelledOrder' || _vm.user.type == 'ToRemind' ? _c('menu-link', {
    attrs: {
      "to": "/orders/confirmation",
      "text": _vm.$t('confirmation'),
      "icon": "add"
    }
  }) : _vm._e(), ['orders', 'importOrders'].indexOf(_vm.$route.name) > -1 ? _c('div', [_vm.getAccess('orders', 'create') && ['NRP', 'CancelledOrder', 'ToRemind', 'TeleConsultant'].indexOf(_vm.user.type) == -1 ? _c('menu-link', {
    attrs: {
      "to": "/orders/import",
      "text": _vm.$t('import_orders'),
      "icon": "add",
      "submenu": "true"
    }
  }) : _vm._e()], 1) : _vm._e(), ['orders', 'apiCreateOrders'].indexOf(_vm.$route.name) > -1 ? _c('div', [_vm.user.type == 'CEO' ? _c('menu-link', {
    attrs: {
      "to": "/orders/api",
      "text": _vm.$t('api'),
      "icon": "import_export",
      "submenu": "true"
    }
  }) : _vm._e()], 1) : _vm._e(), _vm.getPermission('expeditions') ? _c('menu-link', {
    staticClass: "link",
    attrs: {
      "to": "/expeditions",
      "text": _vm.$t('expeditions'),
      "icon": "local_airport"
    }
  }) : _vm._e(), ['expeditions', 'newExpedition'].indexOf(_vm.$route.name) > -1 ? _c('div', [_vm.getAccess('expeditions', 'create') ? _c('menu-link', {
    attrs: {
      "to": "/expeditions/new",
      "text": _vm.$t('new_expedition'),
      "icon": "add",
      "submenu": "true"
    }
  }) : _vm._e()], 1) : _vm._e(), _vm.getPermission('status') && _vm.getAccess('status', 'create') ? _c('menu-link', {
    staticClass: "link",
    attrs: {
      "to": "/status",
      "text": _vm.$t('status'),
      "icon": "sync"
    }
  }) : _vm._e(), _vm.getPermission('users') && _vm.getAccess('users', 'create') ? _c('menu-link', {
    staticClass: "link",
    attrs: {
      "to": "/users",
      "text": _vm.$t('users'),
      "icon": "account_circle"
    }
  }) : _vm._e(), ['users', 'newUser'].indexOf(_vm.$route.name) > -1 ? _c('div', [_c('menu-link', {
    attrs: {
      "to": "/users/new",
      "text": "New user ",
      "icon": "add",
      "submenu": "true"
    }
  })], 1) : _vm._e(), ['users', 'historyUser'].indexOf(_vm.$route.name) > -1 && _vm.getPermission('userHistories') ? _c('div', [_c('menu-link', {
    attrs: {
      "to": "/users/history",
      "text": "History",
      "icon": "history",
      "submenu": "true"
    }
  })], 1) : _vm._e(), _vm.getPermission('roles') && _vm.getAccess('roles', 'create') ? _c('menu-link', {
    staticClass: "link",
    attrs: {
      "to": "/roles",
      "text": _vm.$t('roles'),
      "icon": "lock"
    }
  }) : _vm._e(), ['roles', 'newRole'].indexOf(_vm.$route.name) > -1 ? _c('div', [_c('menu-link', {
    attrs: {
      "to": "/roles/new",
      "text": _vm.$t('new_role'),
      "icon": "add",
      "submenu": "true"
    }
  })], 1) : _vm._e(), _vm.getPermission('teams') ? _c('menu-link', {
    staticClass: "link",
    attrs: {
      "to": "/teams",
      "text": _vm.$t('teams'),
      "icon": "group"
    }
  }) : _vm._e(), ['teams', 'newTeam'].indexOf(_vm.$route.name) > -1 ? _c('div', [_c('menu-link', {
    attrs: {
      "to": "/teams/new",
      "text": _vm.$t('new_team'),
      "icon": "add",
      "submenu": "true"
    }
  })], 1) : _vm._e(), _vm.getPermission('zones') && _vm.getAccess('zones', 'create') || _vm.getPermission('shippings') && _vm.getAccess('shippings', 'create') || _vm.user.type == 'Accountant' || _vm.user.type == 'Return' || _vm.user.type == 'Seller' && _vm.getAccess('shippings', 'read') ? _c('menu-link', {
    staticClass: "link",
    attrs: {
      "to": "#",
      "text": _vm.$t('shipping_order'),
      "icon": "local_shipping",
      "isActive": _vm.isActive
    },
    on: {
      "myFilter": _vm.myFilter
    }
  }) : _vm._e(), _vm.getPermission('zones') && _vm.getAccess('zones', 'create') ? _c('menu-link', {
    staticClass: "link",
    attrs: {
      "to": "/zones",
      "text": _vm.$t('zones'),
      "icon": "map",
      "children": "true"
    }
  }) : _vm._e(), ['zones', 'newZones'].indexOf(_vm.$route.name) > -1 ? _c('div', [_c('menu-link', {
    staticClass: "child",
    attrs: {
      "to": "/zones/new",
      "text": _vm.$t('new_zone'),
      "icon": "add",
      "submenu": "true"
    }
  })], 1) : _vm._e(), _vm.getPermission('shippings') && _vm.getAccess('shippings', 'create') || _vm.user.type == 'Return' || _vm.user.type == 'Seller' && _vm.getAccess('shippings', 'read') ? _c('menu-link', {
    staticClass: "link",
    attrs: {
      "to": "/shippings",
      "text": _vm.$t('shippings'),
      "icon": "map",
      "children": "true"
    }
  }) : _vm._e(), _vm.getPermission('payments') ? _c('menu-link', {
    staticClass: "link",
    attrs: {
      "to": "/payments",
      "text": _vm.$t('payments'),
      "icon": "payment"
    }
  }) : _vm._e(), ['payments', 'newPayments'].indexOf(_vm.$route.name) > -1 && (_vm.getAccess('payments', 'create') || _vm.getAccess('payments', 'update')) ? _c('div', [_c('menu-link', {
    attrs: {
      "to": "/payments/new",
      "text": _vm.$t('new_payment'),
      "icon": "add",
      "submenu": "true"
    }
  })], 1) : _vm._e(), _vm.getPermission('settings') && _vm.getAccess('settings', 'update') ? _c('menu-link', {
    staticClass: "link",
    attrs: {
      "to": "/settings",
      "text": _vm.$t('settings'),
      "icon": "settings"
    }
  }) : _vm._e(), _vm.user.type == 'Seller' ? _c('menu-link', {
    staticClass: "link img",
    attrs: {
      "to": "/createstore",
      "text": "Create Store",
      "img": "https://storeno.b-cdn.net/shipsen/10-2021/1633356154211.png",
      "imghover": "https://storeno.b-cdn.net/shipsen/10-2021/1633356552917.png",
      "target": "_blank"
    }
  }) : _vm._e()], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }